.our-blog {
  height: 190vh;
  background: #f7f7f7;
  overflow: hidden;
}
.row {
  margin-bottom: 0;
}
.row:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}

.process-box {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  box-shadow: 2px 2px 7px 0 #00000057;
}

.process-left:after {
  content: "";
  border-top: 15px solid #ffffff;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  display: inline-grid;
  position: absolute;
  right: -15px;
  top: 42%;
  transform: rotate(45deg);
  box-shadow: 3px -2px 3px 0px #00000036;
  z-index: 1;
}

.process-right:after {
  content: "";
  border-top: 15px solid #ffffff00;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff00;
  display: inline-grid;
  position: absolute;
  left: -15px;
  top: 42%;
  transform: rotate(45deg);
  box-shadow: -1px 1px 3px 0px #0000001a;
  z-index: 1;
}

.process-step {
  background: #ea461d;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  color: #fff;
  height: 100px;
  padding-top: 8px;
  position: relative;
  top: -26px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: -6px 8px 0px 0px #00000014;
}

.process-point-right-first {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 8px solid #ea461d;
  box-shadow: 0 0 0px 6px #3f3d56;
  margin: auto 0;
  position: absolute;
  bottom: 75px;
  left: -63px;
}

.process-point-right-first:before {
  content: "";
  height: 230px;
  width: 10px;
  background: #3f3d56;
  display: inline-grid;
  transform: rotate(20deg);
  position: relative;
  left: -43px;
  top: 10px;
}

.process-point-left-second {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 8px solid #ea461d;
  box-shadow: 0 0 0px 6px #3f3d56;
  margin: auto 0;
  position: absolute;
  bottom: 123px;
  right: -63px;
}

.process-point-left-second:before {
  content: "";
  height: 255px;
  width: 10px;
  background: #3f3d56;
  display: inline-grid;
  transform: rotate(-17deg);
  position: relative;
  left: 41px;
  top: 11px;
}
.process-point-right-third {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 8px solid #ea461d;
  box-shadow: 0 0 0px 6px #3f3d56;
  margin: auto 0;
  position: absolute;
  bottom: 137px;
  left: -63px;
}
.process-point-right-third:before {
  content: "";
  height: 270px;
  width: 10px;
  background: #3f3d56;
  display: inline-grid;
  transform: rotate(16deg);
  position: relative;
  left: -43px;
  top: 11px;
}
.process-point-left-fourth {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 8px solid #ea461d;
  box-shadow: 0 0 0px 6px #3f3d56;
  margin: auto 0;
  position: absolute;
  bottom: 137px;
  right: -63px;
}

.process-point-left-fourth:before {
  content: "";
  height: 249px;
  width: 10px;
  background: #3f3d56;
  display: inline-grid;
  transform: rotate(-18deg);
  position: relative;
  left: 40px;
  top: 10px;
}

.process-point-right-fifth {
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 8px solid #ea461d;
  box-shadow: 0 0 0px 6px #3f3d56;
  margin: auto 0;
  position: absolute;
  bottom: 110px;
  left: -63px;
}

.process-last:before {
  display: none;
}

.process-box p {
  z-index: 9;
}

.process-step p {
  font-size: 20px;
}

.process-step h2 {
  font-size: 39px;
  margin-left: 42px !important;
}
.m-0 {
  margin-left: 20px;
}

.process-step:after {
  content: "";
  border-top: 8px solid #04889800;
  border-bottom: 8px solid #ea461d;
  border-left: 8px solid #04889800;
  border-right: 8px solid #ea461d;
  display: inline-grid;
  position: absolute;
  left: -16px;
  top: 0;
}

.process-step:before {
  content: "";
  border-top: 8px solid #ff000000;
  border-bottom: 8px solid #ea461d;
  border-left: 8px solid #ea461d;
  border-right: 8px solid #ff000000;
  display: inline-grid;
  position: absolute;
  right: -16px;
  top: 0;
}

.process-line-l {
  background: white;
  height: 4px;
  position: absolute;
  width: 140px;
  right: -150px;
  top: 102px;
  z-index: 9;
}
.process-line-r {
  background: white;
  height: 4px;
  position: absolute;
  width: 140px;
  left: -150px;
  top: 122px;
  z-index: 9;
}
.process-line-l-thrd {
  background: white;
  height: 4px;
  position: absolute;
  width: 140px;
  right: -150px;
  top: 132px;
  z-index: 9;
}
.process-line-l-fifth {
  background: white;
  height: 4px;
  position: absolute;
  width: 140px;
  right: -150px;
  top: 112px;
  z-index: 9;
}

.process-line-r-fourth {
  background: white;
  height: 4px;
  position: absolute;
  width: 140px;
  left: -150px;
  top: 132px;
  z-index: 9;
}

@media only screen and (max-height: 640px) {
  .our-blog {
    height: 320vh;
  }
}
@media only screen and (max-width: 375px) {
  .our-blog {
    height: 325vh;
  }
}
@media only screen and (max-width: 600px) and (min-height: 1024px) {
  .our-blog {
    height: 170vh !important;
  }
}
@media only screen and (max-width: 280px) and (min-height: 653px) {
  .our-blog {
    height: 350vh !important;
  }
}
@media only screen and (max-width: 320px) and (min-height: 568px) {
  .our-blog {
    height: 370vh !important;
  }
}
@media only screen and (width: 360px) and (height: 640px) {
  .our-blog {
    height: 310vh !important;
  }
}
@media only screen and (width: 360px) and (height: 740px) {
  .our-blog {
    height: 280vh !important;
  }
}
@media only screen and (width: 375px) and (height: 667px) {
  .our-blog {
    height: 270vh !important;
  }
}
@media only screen and (width: 390px) and (height: 844px) {
  .our-blog {
    height: 230vh !important;
  }
}
@media only screen and (min-width: 393px) and (min-height: 851px) {
  .our-blog {
    height: 230vh !important;
  }
}
@media only screen and (width: 412px) and (height: 914px) {
  .our-blog {
    height: 210vh !important;
  }
}
@media only screen and (width: 412px) and (height: 915px) {
  .our-blog {
    height: 210vh !important;
  }
}
@media only screen and (width: 414px) and (height: 736px) {
  .our-blog {
    height: 260vh !important;
  }
}
@media only screen and (width: 414px) and (height: 896px) {
  .our-blog {
    height: 210vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .process-left:after,
  .process-right:after,
  .process-point-left-second,
  .process-point-left-second:before,
  .process-point-right-fifth,
  .process-point-right-first,
  .process-point-right-third,
  .process-point-right-first:before,
  .process-point-right-third:before,
  .process-point-left-fourth,
  .process-point-left-fourth:before,
  .process-line-r-fourth,
  .process-line-l-fifth,
  .process-line-l-thrd,
  .process-line-r,
  .process-line-l {
    display: none;
  }
  .our-blog {
    height: 295vh;
  }
  .row {
    margin-bottom: 30px;
  }
  .process-step .ml-5 {
    margin-left: 0px !important;
  }
  .process-step h2 {
    margin-left: 0;
  }
  .pheading {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .our-blog {
    height: 260vh;
  }
  .row {
    margin-bottom: 30px;
  }
  .process-step .ml-5 {
    margin-left: -2px !important;
  }
  .process-step h2 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) {
  .our-blog {
    height: 145vh;
  }
  .process-point-right-first {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 142px;
    left: -63px;
  }

  .process-point-right-first:before {
    content: "";
    height: 320px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(10deg);
    position: relative;
    left: -30px;
    top: 15px;
  }

  .process-point-left-second {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 168px;
    right: -57px;
  }

  .process-point-left-second:before {
    content: "";
    height: 355px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-9deg);
    position: relative;
    left: 29px;
    top: 15px;
  }
  .process-point-right-third {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 182px;
    left: -63px;
  }
  .process-point-right-third:before {
    content: "";
    height: 366px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(8deg);
    position: relative;
    left: -28px;
    top: 15px;
  }
  .process-point-left-fourth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 182px;
    right: -63px;
  }

  .process-point-left-fourth:before {
    content: "";
    height: 352px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-8deg);
    position: relative;
    left: 25px;
    top: 15px;
  }

  .process-point-right-fifth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 140px;
    left: -66px;
  }

  .process-last:before {
    display: none;
  }
  .process-line-l {
    background: white;
    height: 4px;
    position: absolute;
    width: 100px;
    right: -120px;
    top: 135px;
    z-index: 9;
  }
  .process-line-r {
    background: white;
    height: 4px;
    position: absolute;
    width: 110px;
    left: -125px;
    top: 155px;
    z-index: 9;
  }
  .process-line-l-thrd {
    background: white;
    height: 4px;
    position: absolute;
    width: 100px;
    right: -120px;
    top: 165px;
    z-index: 9;
  }
  .process-line-r-fourth {
    background: white;
    height: 4px;
    position: absolute;
    width: 100px;
    left: -120px;
    top: 165px;
    z-index: 9;
  }
  .process-line-l-fifth {
    background: white;
    height: 4px;
    position: absolute;
    width: 100px;
    right: -120px;
    top: 135px;
    z-index: 9;
  }

  .process-step .ml-5 {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 600px) {
  .our-blog {
    height: 320vh;
  }
}
@media only screen and (min-width: 1024px) and (min-height: 768px) {
  .our-blog {
    height: 250vh;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .our-blog {
    height: 250vh;
  }
}
@media only screen and (min-width: 1024px) and (min-height: 1366px) {
  .our-blog {
    height: 150vh;
  }
}
@media only screen and (min-width: 1280px) {
  .our-blog {
    height: 185vh;
  }
  .process-point-right-first {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 113px;
    left: -63px;
  }

  .process-point-right-first:before {
    content: "";
    height: 277px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(17deg);
    position: relative;
    left: -45px;
    top: 11px;
  }

  .process-point-left-second {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 141px;
    right: -63px;
  }

  .process-point-left-second:before {
    content: "";
    height: 315px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-15deg);
    position: relative;
    left: 44px;
    top: 12px;
  }
  .process-point-right-third {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 155px;
    left: -63px;
  }
  .process-point-right-third:before {
    content: "";
    height: 330px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(15deg);
    position: relative;
    left: -43px;
    top: 12px;
  }
  .process-point-left-fourth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 154px;
    right: -63px;
  }

  .process-point-left-fourth:before {
    content: "";
    height: 310px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-15deg);
    position: relative;
    left: 42px;
    top: 12px;
  }

  .process-point-right-fifth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 125px;
    left: -63px;
  }

  .process-last:before {
    display: none;
  }
  .process-line-l {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 115px;
    z-index: 9;
  }
  .process-line-r {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    left: -150px;
    top: 135px;
    z-index: 9;
  }
  .process-line-l-thrd {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 145px;
    z-index: 9;
  }
  .process-line-r-fourth {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    left: -150px;
    top: 145px;
    z-index: 9;
  }
  .process-line-l-fifth {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 125px;
    z-index: 9;
  }
  .process-step .ml-5 {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 1280px) and (min-height: 600px) {
  .our-blog {
    height: 290vh;
  }
}
@media only screen and (min-width: 1280px) and (min-height: 760px) {
  .our-blog {
    height: 230vh;
  }
}
@media only screen and (min-width: 1280px) and (min-height: 950px) {
  .our-blog {
    height: 190vh;
  }
}
@media only screen and (min-width: 1294px) {
  .our-blog {
    height: 268vh;
  }
  .process-point-right-first {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 113px;
    left: -63px;
  }

  .process-point-right-first:before {
    content: "";
    height: 276px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(17deg);
    position: relative;
    left: -45px;
    top: 11px;
  }

  .process-point-left-second {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 141px;
    right: -63px;
  }

  .process-point-left-second:before {
    content: "";
    height: 315px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-15deg);
    position: relative;
    left: 44px;
    top: 12px;
  }
  .process-point-right-third {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 155px;
    left: -63px;
  }
  .process-point-right-third:before {
    content: "";
    height: 330px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(15deg);
    position: relative;
    left: -43px;
    top: 12px;
  }
  .process-point-left-fourth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 154px;
    right: -63px;
  }

  .process-point-left-fourth:before {
    content: "";
    height: 310px;
    width: 10px;
    background: #3f3d56;
    display: inline-grid;
    transform: rotate(-15deg);
    position: relative;
    left: 42px;
    top: 12px;
  }

  .process-point-right-fifth {
    background: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 8px solid #ea461d;
    box-shadow: 0 0 0px 6px #3f3d56;
    margin: auto 0;
    position: absolute;
    bottom: 125px;
    left: -63px;
  }

  .process-last:before {
    display: none;
  }
  .process-line-l {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 115px;
    z-index: 9;
  }
  .process-line-r {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    left: -152px;
    top: 135px;
    z-index: 9;
  }
  .process-line-l-thrd {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 145px;
    z-index: 9;
  }
  .process-line-r-fourth {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    left: -152px;
    top: 145px;
    z-index: 9;
  }
  .process-line-l-fifth {
    background: white;
    height: 4px;
    position: absolute;
    width: 140px;
    right: -150px;
    top: 125px;
    z-index: 9;
  }
}
@media only screen and (min-width: 1366px) {
  .our-blog {
    height: 280vh;
  }
}
@media only screen and (min-width: 1366px) and (min-height: 768px) {
  .our-blog {
    height: 230vh;
  }
}
@media only screen and (min-width: 1440px) and (min-height: 850px) {
  .our-blog {
    height: 200vh;
  }
}
@media only screen and (min-width: 1680px) and (min-height: 1050px) {
  .our-blog {
    height: 170vh;
  }
}
@media only screen and (min-width: 1920px) and (min-height: 1200px) {
  .our-blog {
    height: 150vh;
  }
}
