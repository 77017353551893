hr {
  width: 40%;
  margin: 20px auto;
  color: #ea4b23;
  border: 2px solid;
}
.testimonial {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  height: 90vh;
}

@media only screen and (max-width: 786px) {
  .testimonial {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    height: 140vh;
  }
  h2 {
    font-size: x-large;
  }
  hr {
    width: 90%;
  }
}
@media only screen and (min-height: 820px) {
  .testimonial {
    height: 110vh;
  }
}

.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}
